import React from 'react';
import {
  Col,
  Row,
  Image,
  Card
} from 'react-bootstrap';
import icon_2 from '../../images/COLOR_ICON_2.png';
import icon_1 from '../../images/COLOR_ICON_1.png';
import icon_3 from '../../images/COLOR_ICON_3.png';
import icon_4 from '../../images/COLOR_ICON_4.png';
import icon_5 from '../../images/COLOR_ICON_5.png';
import icon_6 from '../../images/COLOR_ICON_6.png';
import './methods-screen.component.scss';

export const AppMethodsScreen = () => (
  <Row>
    <Col xs={12} className="mb-3 mb-lg-5 d-flex justify-content-center">
      <div className="w-75 px-lg-5 text-center d-none d-lg-block">
        <p className="ca-title-font mb-0 ca-3-line-height">Our methods and resources will revolutionize your path to success</p>
      </div>
      <div className="w-100 text-center d-block py-3 px-1 d-lg-none">
        <p className="ca-title-font mb-0 ca-2-line-height">Our methods and resources will revolutionize your path to success</p>
      </div>
    </Col>

    <Col lg={4} xs={12} className="d-flex align-items-center">
      <Card className="border-0 d-flex flex-row flex-lg-column justify-content-center align-items-center mx-auto">
        <div className="d-flex justify-content-center">
          <Image
            src={icon_2}
            alt="career coaching image"
            height="90"
            width="90"
            className="text-center"
          />
        </div>
        <Card.Body className="text-center">
           <div className="ca-method-title-box">
            <Card.Title className="mb-0 ca-subtitle-font">Career advancement</Card.Title>
            <Card.Title className="ca-subtitle-font">Coaching</Card.Title>
           </div>
          <Card.Text className="">Create awareness, generate actions and accelerate your growth</Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col lg={4} xs={12} className="d-flex align-items-center">
      <Card className="border-0 d-flex flex-row flex-lg-column justify-content-center align-items-center mx-auto">
        <div className="d-flex justify-content-center">
          <Image
            src={icon_3}
            alt="career coaching image"
            height="90"
            width="90"
            className="text-center"
          />
        </div>
        <Card.Body className="text-center">
           <div className="ca-method-title-box d-flex align-items-center justify-content-center">
            <Card.Title className="mb-0 ca-subtitle-font">Resume Tuning</Card.Title>
           </div>
          <Card.Text className="">Optimize your resume with industry specific keywords</Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col lg={4} xs={12} className="d-flex align-items-center">
      <Card className="border-0 d-flex flex-row flex-lg-column justify-content-center align-items-center mx-auto">
        <div className="d-flex justify-content-center">
          <Image
            src={icon_1}
            alt="career coaching image"
            height="90"
            width="90"
            className="text-center"
          />
        </div>
        <Card.Body className="text-center">
          <div className="ca-method-title-box">
            <Card.Title className="mb-0 ca-subtitle-font">Personalized Goal</Card.Title>
            <Card.Title className="mb-0 ca-subtitle-font">Roadmap</Card.Title>
          </div>
          <Card.Text>Receive detailed direction and let us help plan your next career move</Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col lg={4} xs={12} className="d-flex align-items-center">
      <Card className="border-0 d-flex flex-row flex-lg-column justify-content-center align-items-center mx-auto">
        <div className="d-flex justify-content-center">
          <Image
            src={icon_5}
            alt="return client image"
            height="90"
            width="90"
            className="text-center"
          />
        </div>
        <Card.Body className="text-center">
          <div className="ca-method-title-box">
            <Card.Title className="mb-0 ca-subtitle-font">Return Client</Card.Title>
            <Card.Title className="mb-0 ca-subtitle-font">benefits</Card.Title>
          </div>
          <Card.Text>Commit long-term and reap the benefits of success</Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col lg={4} xs={12} className="d-flex align-items-center">
      <Card className="border-0 d-flex flex-row flex-lg-column justify-content-center align-items-center mx-auto">
        <div className="d-flex justify-content-center">
          <Image
            src={icon_4}
            alt="interview preparation image"
            height="90"
            width="90"
            className="text-center"
          />
        </div>
        <Card.Body className="text-center">
          <div className="ca-method-title-box">
            <Card.Title className="mb-0 ca-subtitle-font">Interview</Card.Title>
            <Card.Title className="mb-0 ca-subtitle-font">Preparation</Card.Title>
          </div>
          <Card.Text>Practice makes perfect. Tune your interview responses</Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col lg={4} xs={12} className="d-flex align-items-center">
      <Card className="border-0 d-flex flex-row flex-lg-column justify-content-center align-items-center mx-auto">
        <div className="d-flex justify-content-center">
          <Image
            src={icon_6}
            alt="interview preparation image"
            height="90"
            width="90"
            className="text-center"
          />
        </div>
        <Card.Body className="text-center">
          <div className="ca-method-title-box">
            <Card.Title className="mb-0 ca-subtitle-font">Employment</Card.Title>
            <Card.Title className="mb-0 ca-subtitle-font">direction</Card.Title>
          </div>
          <Card.Text>Receive a game plan and be prepared every step of the way</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);
