import React from 'react';
import {
  Row,
  Col,
  Card,
  Image,
} from 'react-bootstrap';
import './about.component.scss';
import aboutImage from '../../images/Cyberphoto.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle }from '@fortawesome/free-regular-svg-icons';

export const AppAbout = () => (
  <Row className="mt-lg-5">
    <Col xs={12} lg={6} className="d-flex justify-content-center align-items-center">
      <Card className="border-0">
        <Card.Body className="pl-0">
          <Card.Title className="ca-title-font">Who we are?</Card.Title>
          <Card.Text>Military veteran owned company with over 30 years of combined experience in the ever- changing
            Information Technology industry. Fierce IT Solutions team expertise spans both in the commercial
            and government side. Our clients reach out to us with confidence that we have the expertise to provide
            a long-term solution. Our solid foundation, proven processes and procedures has allowed us to help countless
            individuals achieve their goals. We pride ourselves in providing the best customer service
            so that our clients can trust us and commit to our strategic plan.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col xs={12} lg={6} className="mt-4 mb-3">
      <Image
        className="img-fluid"
        src={aboutImage}
      />
    </Col>

    <Col xs={12} className="mt-5 my-lg-5 d-flex justify-content-center">
      <Card className="w-75 text-lg-center border-0 d-none d-lg-block mt-5">
        <Card.Body className="ca-about-our-missing-div px-5">
          <Card.Title className="ca-title-font">Our Mission</Card.Title>
          <Card.Text className="">Our mission is to change the perspective of an IT professional in regards to their career. Providing our clients with the proper tools they need to take</Card.Text>
          <Card.Text className="">control of their career path and demonstrating the long-term benefits and rewards.</Card.Text>
        </Card.Body>
      </Card>

      <Card className="w-100 text-lg-center border-0 d-block d-lg-none">
        <Card.Body className="ca-about-our-missing-div px-0">
          <Card.Title className="ca-title-font">Our Mission</Card.Title>
          <Card.Text className="">Our mission is to change the perspective of an IT professional in regards to their career. Providing our clients with the proper tools they need to take control of their career path and demonstrating the long-term benefits and rewards</Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col xs={12} lg={6} className="my-1 my-lg-5">
      <Card className="border-0">
        <Card.Body className="px-0">
          <Card.Title className="ca-title-font mb-0">Our Professional</Card.Title>
          <Card.Title className="ca-title-font">Experience</Card.Title>
          <Card.Text>We use our professional experience in Cyber security engineering, Systems engineering,
            networking engineering and Information Assurance (IA) to guide our clients
            through their professional career path. Our team members have experience
            working with fortune 500 companies as well as different government
            entities throughout the IT industry.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={12} lg={5} className="offset-lg-1 d-flex flex-column align-items-start mt-3 mt-lg-5 mx-auto justify-content-around">
      <div className="w-100 d-flex justify-content-start align-items-center pb-3 mb-3 mb-lg-0 border-bottom ca-margin-top-checklist">
        <FontAwesomeIcon icon={ faCheckCircle } className="ca-orange" size="2x"/>
        <p className="ml-4">30+ years of combined IT experience</p>
      </div>
      <div className="w-100 d-flex justify-content-start align-items-center pb-3 mb-3 mb-lg-0 border-bottom">
        <FontAwesomeIcon icon={ faCheckCircle } className="ca-orange" size="2x"/>
        <p className="ml-4">IT Military Veteran professionals</p>
      </div>
      <div className="w-100 d-flex justify-content-start align-items-center pb-3 mb-3 mb-lg-0 border-bottom">
        <FontAwesomeIcon icon={ faCheckCircle } className="ca-orange" size="2x"/>
        <p className="ml-4">Cyber Security expertise</p>
      </div>
      <div className="w-100 d-flex justify-content-start align-items-center pb-3 mb-3 mb-lg-0 border-bottom">
        <FontAwesomeIcon icon={ faCheckCircle } className="ca-orange" size="2x"/>
        <p className="ml-4">Systems and Network Engineering experience</p>
      </div>
      <div className="w-100 d-flex justify-content-start align-items-center pb-3 mb-3 mb-lg-0 border-bottom">
        <FontAwesomeIcon icon={ faCheckCircle } className="ca-orange" size="2x"/>
        <p className="ml-4">Business and negotiating experience</p>
      </div>
    </Col>
  </Row>
);
