import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import './testimonial.component.scss';

export const AppTestimonialScreen = () => (
  <Row>
    <Col xs={12} className="my-5">
      <div className="w-100 text-right ca-testimonial-pale-background">
        <p className="mb-0 ca-title-font ca-3-line-height">Testimonials from our</p>
        <p className="mb-0 ca-title-font ca-3-line-height">clients</p>
      </div>
    </Col>
    <Col xs={12} lg={8} className="mb-3 mt-5 mx-auto offset-lg-0 mx-lg-0">
      <div className="ca-testimonial-div d-flex flex-column justify-content-between align-items-center p-3">
        <p className="ca-oblique"> “Professional, knowledgeable, and exceptional career coach. I was transitioning out of the military and needed some guidance with my career and Fierce IT Solution delivered. We practiced interviews countless hours, we made plans and goals for my career. I followed all of their advice before the interview and I got my job, and then followed up with career path advice once I obtained my job. True professionals in the IT field, I would not be where I am at today without the professional knowledge and guidance from Fierce IT Solutions.”</p>
        <div className="w-100 mt-4">
          <p className="ca-grey font-weight-bolder">Mark M.</p>
          <p className="font-weight-bold">Senior I.T. Consultant</p>
        </div>
      </div>
    </Col>
    <Col xs={12} md={8} className="my-3 mx-auto mr-md-0 offset-md-4">
      <div className="ca-testimonial-div d-flex flex-column justify-content-between align-items-center p-3">
        <p className="ca-oblique">"I feel empowered during our discussion sessions because Fierce IT Solutions has helped me believe in myself and they understood the situation I was in. The powerful discussions we had prompted me to go within and find answers on my own and take control of my career. Fierce IT Solutions coaching over the past three months has been instrumental in making my transition very successful. I was able to find the perfect position and company. Thank you for believing in me and supporting my efforts."</p>
        <div className="w-100 mt-4">
          <p className="ca-grey font-weight-bolder">Jessie L.</p>
          <p className="font-weight-bold">Systems Engineer</p>
        </div>
      </div>
    </Col>
    <Col xs={12} lg={8} className="my-3 mx-auto offset-lg-0 mx-lg-0">
      <div className="ca-testimonial-div d-flex flex-column justify-content-between align-items-center p-3">
        <p className="ca-oblique">"I cannot find words to express my appreciation for all the assistance this company gave me in my job search. They gave me expert advice and assured me that if I trust the process, I would have countless opportunities. I followed their advice and guidance for a few months and it paid off big time. Your roadmap, discussions and career advancement expertise helped me tremendously long term. Thank you and I cannot wait to work with your company again in the future."</p>
        <div className="w-100 mt-4">
          <p className="ca-grey font-weight-bolder">Jose A.</p>
          <p className="font-weight-bold">Senior Systems Engineer</p>
        </div>
      </div>
    </Col>
  </Row>
);
