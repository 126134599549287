import React from 'react';
import {
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import { AppLine } from '../../shared/components';
import './services.component.scss';

export const AppServicesScreen = () => (
  <Row>
    <Col xs={12} className="mt-lg-5 mt-1 ca-title-pale-background">
      <p className="ca-title-font">Our Services</p>
      <p className="ca-subtitle-font">The longer your commitment, the shorter the path to success</p>
    </Col>

     <Col xs={12} lg={4} className="mt-1 mt-lg-5 p-3">
      <Card className="border-0 services-card-wrapper d-flex flex-column justify-content-between">
        <Card.Body className="pl-0">
          <Card.Title className="ca-orange ca-subtitle-font">Career advancement</Card.Title>
          <Card.Text className="">This service is designed to help our clients prepare for the future through educational direction, creating awareness, influencing our clients to act and accelerating their growth.</Card.Text>
        </Card.Body>
        <div className="">
          <AppLine/>
        </div>
      </Card>
    </Col>

     <Col xs={12} lg={4} className="mt-1 mt-lg-5 p-3">
      <Card className="border-0 services-card-wrapper d-flex flex-column justify-content-between">
        <Card.Body className="pl-0">
          <Card.Title className="ca-orange ca-subtitle-font">Resume Writing & Tuning</Card.Title>
          <Card.Text className="">Our team offers specialized industry leading resume composition.
            Our resume incorporates marketing aspects to effectively market our clients through the use of concise writing.
            We integrate information received from big tech employers to improve the chance
            of landing an interview for our clients.
          </Card.Text>
        </Card.Body>
        <div className="">
          <AppLine/>
        </div>
      </Card>
    </Col>

     <Col xs={12} lg={4} className="mt-1 mt-lg-5 p-3">
      <Card className="border-0 services-card-wrapper d-flex flex-column justify-content-between">
        <Card.Body className="pl-0">
          <Card.Title className="ca-orange ca-subtitle-font">Personalized Goal Roadmap</Card.Title>
          <Card.Text className="">Our team will plan and generate a detailed goal roadmap that will display
           all the different steps that our clients would need to take to
           reach their long- term goals.
          </Card.Text>
        </Card.Body>
        <div className="">
          <AppLine/>
        </div>
      </Card>
    </Col>

     <Col xs={12} lg={4} className="mt-1 mt-lg-5 p-3">
      <Card className="border-0 services-card-wrapper d-flex flex-column justify-content-between">
        <Card.Body className="pl-0">
          <Card.Title className="ca-orange ca-subtitle-font">Return Client benefits</Card.Title>
          <Card.Text className="">Our clients enjoy from a variety of benefits when they consult
            with us numerous times. Returning clients benefits include service discounts,
            long term career assessment and access to our business network.
          </Card.Text>
        </Card.Body>
        <div className="">
          <AppLine/>
        </div>
      </Card>
    </Col>

     <Col xs={12} lg={4} className="mt-1 mt-lg-5 p-3">
      <Card className="border-0 services-card-wrapper d-flex flex-column justify-content-between">
        <Card.Body className="pl-0">
          <Card.Title className="ca-orange ca-subtitle-font">Interview Preparation</Card.Title>
          <Card.Text className="">Prepare for the pressures and questions of a live interview
           with a mock interview session. Practice countless times with our professional team and polish your interview skills.
          </Card.Text>
        </Card.Body>
        <div className="">
          <AppLine/>
        </div>
      </Card>
    </Col>

     <Col xs={12} lg={4} className="mt-1 mt-lg-5 p-3">
      <Card className="border-0 services-card-wrapper d-flex flex-column justify-content-between">
        <Card.Body className="pl-0">
          <Card.Title className="ca-orange ca-subtitle-font">Employment direction</Card.Title>
          <Card.Text className="">Sit down with our team and go over your expectations
          for your career and current employment. Receive professional guidance that suits your skills and long-term goals.
          </Card.Text>
        </Card.Body>
        <div className="">
          <AppLine/>
        </div>
      </Card>
    </Col>

     <Col xs={12} lg={4} className="mt-1 mt-lg-5 p-3">
      <Card className="border-0 services-card-wrapper d-flex flex-column justify-content-between">
        <Card.Body className="pl-0">
          <Card.Title className="ca-orange ca-subtitle-font">Career planning</Card.Title>
          <Card.Text className="">Meet weekly with our professional team and take
          control of your career. Meetings and discussion sessions provide our clients
          the time they need to reflect on their career path.
          </Card.Text>
        </Card.Body>
        <div className="">
          <AppLine/>
        </div>
      </Card>
    </Col>

    <Col xs={12} lg={4} className="mt-1 mt-lg-5 p-3">
      <Card className="border-0 services-card-wrapper d-flex flex-column justify-content-between">
        <Card.Body className="pl-0">
          <Card.Title className="ca-orange ca-subtitle-font">Job search assistance</Card.Title>
          <Card.Text className="">Work along side our professional team to identify
          and find a position that will provide technical exposure and allow you to grow professionally.
          </Card.Text>
        </Card.Body>
        <div className="">
          <AppLine/>
        </div>
      </Card>
    </Col>
  </Row>
);
