import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import './App.scss';
import {
  AppMainScreen,
  AppNavbar,
  AppMethodsScreen,
  AppTestimonialScreen,
  AppServicesScreen,
  AppAbout,
  AppContactUs,
} from './components';

function App() {
  return (
    <div className="App">
      <Router>
        <Container>
          <AppNavbar />
        </Container>
        <Switch>

          <Route path="/contact-us">
            <Container fluid={false}>
              <AppContactUs />
            </Container>
          </Route>

          <Route path="/services">
            <Container fluid={false}>
              <AppServicesScreen />
            </Container>
          </Route>

          <Route path="/about">
            <Container fluid={false}>
              <AppAbout />
            </Container>
          </Route>

          <Route path="/">
            <Container fluid={false}>
              <AppMainScreen />
              <AppMethodsScreen />
              <AppTestimonialScreen />
            </Container>
          </Route>

        </Switch>
      </Router>

      <Container fluid={true} className="mt-5">
        <Row>
          <Col xs={12} className="mt-5 py-4 pr-lg-5 d-flex justify-content-center justify-content-lg-end align-items-center ca-footer ca-background-brown">
            <div className="d-flex flex-column justify-content-center align-items-center ca-footer-social-wrapper" onClick={() => window.open('https://www.linkedin.com/in/fierce-it-solutions-613252207/', '_blank')}>
              <div className="ca-footer-linkedin-wrapper">
                <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
              </div>
              <p className="ca-white font-weight-bold mt-2">Follow us</p>
              <p className="ca-white">Temecula, CA</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
