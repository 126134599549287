import React, { useState } from 'react';
import logo from '../../images/FTS.png';
import sandwich from '../../images/menu_alt_05.png';
import {
  ORANGE,
} from '../../shared/constants';
import {
  Row,
  Navbar,
  Nav,
  Button,
  Image,
} from 'react-bootstrap';
import {
  Link
} from "react-router-dom";
import './app-navbar.component.scss';

export const AppNavbar = () =>  {
  const [expanded, setExpanded] = useState(false);
  return (
    <Row className="mb-lg-1 mb-3">
      <Navbar bg="transparent" expand="lg" className="w-100" expanded={expanded}>
        <Navbar.Brand className="mr-3">
          <Link className="nav-link pl-0" to="/home">
            <Image
              src={logo}
              width="90"
              height="90"
              className="d-inline-block align-top"
              alt="FierceIt Logo"
              />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" onClick={() => setExpanded(expanded ? false : true)}>
          <Image
            src={sandwich}
            width="15"
            height="15"
            className=""
            alt="Toggler"
          />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="w-100 justify-content-between">
            <div className="d-flex flex-lg-row flex-column ml-lg-5 ">
              <Link className="nav-link mr-5 pr-3" to="/services" onClick={() => setExpanded(false)}>Services</Link>
              <Link className="nav-link" to="/about" onClick={() => setExpanded(false)}>About</Link>
            </div>

            <Link to="/contact-us" onClick={() => setExpanded(false)}>
              <Button variant="primary" className="ca-navbar-button">
                Contact us
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Row>
  );
}

