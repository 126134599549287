import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap';
import './contact-us.component.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle }from '@fortawesome/free-regular-svg-icons';

interface IState {
  name: string;
  email: string;
  number: string;
  title: string;
  location: string;
  years: string;
  message: string;
  isSuccessful: boolean | null;
  validated: boolean;
}

const emailStatus = (status: boolean | null) => {
  if (status) {
    return (
      <Col lg={4} xs={10} className="d-flex flex-row justify-content-center align-items-center mt-5 ca-contact-us-error">
        <FontAwesomeIcon icon={faCheckCircle} className="ca-orange d-inline" size="2x" />
        <p className="d-inline ml-3">EMAIL SENT</p>
      </Col>
    );
  }

  if (status === false) {
    return (
      <>
        <Col lg={4} xs={10} className="d-flex flex-column justify-content-center mt-5 ca-contact-us-error">
          <FontAwesomeIcon icon={faTimesCircle} className="ca-red d-inline" size="2x" />
          <p>Whoops...</p>
          <p>Something went wrong. Please try again.</p>
        </Col>
      </>
    );
  }

  if (status === null) {
    return (
      <>
        <Col lg={4} xs={10} className="d-flex align-items-end mt-5 ca-contact-us-button">
          <Button variant="primary" type="submit" className="ca-w-65">SEND</Button>
        </Col>
      </>
    );
  }
}

export const AppContactUs = () => {
  const initialState: IState = {
    name: '',
    email: '',
    number: '',
    title: '',
    location: '',
    years: '',
    message: '',
    isSuccessful: null,
    validated: false,
  };

  const clearState = () => {
    setState({...initialState, validated: false, isSuccessful: true,});
  }

  const onChange = (e: any) => {
    const {name, value} = e.target;
    setState(prevState => ({...prevState, [name]: value}));
  }
  const [{ name, email, number, title, location, years, message, isSuccessful, validated}, setState] = useState<IState>(initialState)


  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      setState(prevState => ({...prevState, validated: true}));
      return;
    }

    const body = {
      name,
      email,
      number,
      title,
      location,
      years,
      message,
    };

    try {
      await fetch('https://us-central1-fierce-it.cloudfunctions.net/app/contact-us-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
      });
      clearState();
    } catch (error) {
      setState(prevState => ({...prevState, isSuccessful: false}));
    }
  };

  return (
    <Row>
      <Col xs={12} className="py-5 my-1 my-lg-5 ca-contact-us-header d-flex align-items-center ca-contact-us-pale-background">
        <p className="ca-title-font">Take control of your career, let’s communicate</p>
      </Col>

      <Col xs={12} className="mb-5">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} lg={8}>
              <Form.Group className="mt-4">
                <Form.Label>Full Name *</Form.Label>
                <Form.Control required type="text" name="name" value={name} onChange={e => onChange(e)} />
                <Form.Control.Feedback type="invalid">
                  Please provide your name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} lg={8}>
              <Form.Group>
                <Form.Label>Email *</Form.Label>
                <Form.Control required type="email" name="email" value={email} onChange={e => onChange(e)}/>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid, non-empty email
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} lg={8}>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" value={number} name="number" onChange={e => onChange(e)}/>
              </Form.Group>
            </Col>

            <Col xs={12} lg={8}>
              <Form.Group>
                <Form.Label>Current Title</Form.Label>
                <Form.Control type="text" value={title} name="title" onChange={e => onChange(e)} />
              </Form.Group>
            </Col>

            <Col lg={4}></Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Control type="text"  value={location} name="location" onChange={e => onChange(e)}/>
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Years of Experience</Form.Label>
                <Form.Control as="select" value={years} name="years" onChange={e => onChange(e)}>
                  <option value="none">no experience</option>
                  <option value="less than one"> less 1 year </option>
                  <option value="one to three"> 1 -3 years </option>
                  <option value="four to seven"> 4 - 7 years </option>
                  <option value="more than seven"> 7+ years </option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col xs={12} lg={5}>
              <Form.Group className="mb-0">
                <Form.Label>Message *</Form.Label>
                <Form.Control as="textarea" required rows={5} value={message} name="message" onChange={e => onChange(e)}></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please add a message
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            {
              emailStatus(isSuccessful)
            }
          </Row>
        </Form>
      </Col>
    </Row>
  );

}
