import React from 'react';

interface IProps {
  color?: string;
  marginTop?: string;
}

export const AppLine = ({color, marginTop}: IProps) => {
  const styles = {
    line: {
      height: '2.5px',
      width: '30%',
      backgroundColor: color || '#FF6F59',
    },
  };

  return (
    <div style = { styles.line } >
    </div >
  );
}
