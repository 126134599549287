import React from 'react';
import {
  Row,
  Col,
  Jumbotron,
  Container,
  Image
} from 'react-bootstrap';
import './main-screen.scss';
import rightImage from '../../images/hero1-80.jpg'

export const AppMainScreen = () => (
  <Row>
    <Jumbotron fluid className="w-100 main-screen-ca-jumbotron p-2 pt-0 ">
      <Container>
        <Row className="align-items-center">
          <Col
            xs={12} lg={6}
            className="d-flex align-items-lg-center ca-main-screen-text-section--small d-block d-lg-none p-3">
            <div className="">
              <p className="ca-title-font">Need a jump start in your career?</p>
              <p className="ca-subtitle-font mt-4">Allow us to help you develop a plan that can lead to new opportunities within your career. Our team has proven results with both short and long-term career coaching.</p>
            </div>
          </Col>
          <Col
            xs={12} lg={6}
            className="align-items-lg-center ca-main-screen-text-section d-none d-lg-block">
            <div className="">
              <p className="ca-title-font mb-4 ca-3-line-height">Need a jump start in your career?</p>
              <p className="ca-subtitle-font">Allow us to help you develop a plan that can lead to new opportunities within your career. Our team has proven results with both short and long-term career coaching.</p>
            </div>
          </Col>
          <Col xs={10} lg={6} className="mx-auto mt-5">
            <Image
              fluid
              src={rightImage}
              className="thumbnail"
              alt="FierceIt Logo"
            />
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  </Row>
);


